{
  "locale": "en",
  "translations": {
    "ngb.alert.close": "Close",
    "ngb.timepicker.HH": "HH",
    "ngb.toast.close-aria": "Close",
    "ngb.pagination.first": "««",
    "ngb.datepicker.select-month": "Select month",
    "ngb.datepicker.previous-month": "Previous month",
    "ngb.progressbar.value": "{$INTERPOLATION}",
    "ngb.carousel.slide-number": " Slide {$INTERPOLATION} of {$INTERPOLATION_1} ",
    "ngb.timepicker.hours": "Hours",
    "ngb.pagination.previous": "«",
    "ngb.carousel.previous": "Previous",
    "ngb.timepicker.MM": "MM",
    "ngb.pagination.next": "»",
    "ngb.datepicker.select-year": "Select year",
    "ngb.datepicker.next-month": "Next month",
    "ngb.carousel.next": "Next",
    "ngb.timepicker.minutes": "Minutes",
    "ngb.pagination.last": "»»",
    "ngb.timepicker.increment-hours": "Increment hours",
    "ngb.pagination.first-aria": "First",
    "ngb.pagination.previous-aria": "Previous",
    "ngb.timepicker.decrement-hours": "Decrement hours",
    "ngb.pagination.next-aria": "Next",
    "ngb.timepicker.increment-minutes": "Increment minutes",
    "ngb.pagination.last-aria": "Last",
    "ngb.timepicker.decrement-minutes": "Decrement minutes",
    "ngb.timepicker.SS": "SS",
    "ngb.timepicker.seconds": "Seconds",
    "ngb.timepicker.increment-seconds": "Increment seconds",
    "ngb.timepicker.decrement-seconds": "Decrement seconds",
    "ngb.timepicker.PM": "{$INTERPOLATION}",
    "ngb.timepicker.AM": "{$INTERPOLATION}",
    "Icon.Activate": "<i class='fa fa-check'></i>",
    "Icon.Add": "<i class='fa fa-plus'></i>",
    "Icon.Append": "<i class='fa fa-plus'></i>",
    "Icon.Apply": "<i class='fa fa-check'></i>",
    "Icon.Approve": "<i class='fa fa-check'></i>",
    "Icon.BalanceInquiry": "<i class='fa fa-search-plus'></i>",
    "Icon.Calculate": "<i class='fa fa-calculator'></i>",
    "Icon.Callback": "<i class='fa fa-reply'></i>",
    "Icon.Cancel": "<i class='fa fa-close'></i>",
    "Icon.CapturePhoto": "<i class='fa fa-camera'></i>",
    "Icon.Clean": "<i class='fa fa-eraser'></i>",
    "Icon.Clear": "<i class='fa fa-eraser'></i>",
    "Icon.Close": "<i class='fa fa-close'></i>",
    "Icon.Collapse": " <i class='fa fa-compress'></i>",
    "Icon.Confirm": "<i class='fa fa-check'></i>",
    "Icon.Copy": "<i class='fa fa-files-o'></i>",
    "Icon.Create": "<i class='fa fa-save'></i>",
    "Icon.CreateRequest": "<i class='fa fa-plus'></i>",
    "Icon.Decline": "<i class='fa fa-close'></i>",
    "Icon.Delete": "<i class='fa fa-trash-o'></i>",
    "Icon.Disable": "<i class='fa fa-close'></i>",
    "Icon.Download": "<i class='fa fa-download'></i>",
    "Icon.Duplicate": "<i class='fa fa-files-o'></i>",
    "Icon.Enable": "<i class='fa fa-check'></i>",
    "Icon.Exchange": " <i class='fa fa-exchange'></i>",
    "Icon.Exit": "<i class='fa fa-sign-out'></i>",
    "Icon.Export": "<i class='fa fa-file-o'></i>",
    "Icon.Filter": "<i class='fa fa-filter'></i>",
    "Icon.Import": "<i class='fa fa-upload'></i>",
    "Icon.Inquiry": "<i class='fa fa-search-plus'></i>",
    "Icon.Insert": "<i class='fa fa-save'></i>",
    "Icon.Load": "<i class='fa fa-eye'></i>",
    "Icon.Lock": "<i class='fa fa-lock'></i>",
    "Icon.OK": "<i class='fa fa-check'></i>",
    "Icon.Overwrite": "<i class='fa fa-recycle'></i>",
    "Icon.Payment": "<i class='fa fa-credit-card'></i>",
    "Icon.Print": "<i class='fa fa-print'></i>",
    "Icon.Refresh": "<i class='fa fa-refresh'></i>",
    "Icon.Register": "<i class='fa fa-plus'></i>",
    "Icon.Reload": "<i class='fa fa-recycle'></i>",
    "Icon.Remove": "<i class='fa fa-trash-o'></i>",
    "Icon.Resend": "<i class='fa fa-send'></i>",
    "Icon.Reset": "<i class='fa fa-eraser'></i>",
    "Icon.ResetData": "<i class='fa fa-eraser'></i>",
    "Icon.Restore": "<i class='fa fa-recycle'></i>",
    "Icon.Return": "<i class='fa fa-reply'></i>",
    "Icon.Reversal": "<i class='fa fa-close'></i>",
    "Icon.Save": "<i class='fa fa-save'></i>",
    "Icon.Search": "<i class='fa fa-search-plus'></i>",
    "Icon.Send": "<i class='fa fa-send'></i>",
    "Icon.SendEmail": "<i class='fa fa-envelope'></i>",
    "Icon.SendRequest": "<i class='fa fa-send'></i>",
    "Icon.SendSMS": "<i class='fa fa-mobile fa-lg'></i>",
    "Icon.Settle": "<i class='fa fa-calculator'></i>",
    "Icon.SignIn": "<i class='fa fa-sign-in'></i>",
    "Icon.SignInUp": " <i class='fa fa-user'></i>",
    "Icon.SignOut": "<i class='fa fa-sign-out'></i>",
    "Icon.Sort": "<i class='fa fa-sort'></i>",
    "Icon.Submit": "<i class='fa fa-send'></i>",
    "Icon.Sync": "<i class='fa fa-exchange'></i>",
    "Icon.TopUp": "<i class='fa fa-credit-card'></i>",
    "Icon.Transfer": "<i class='fa fa-credit-card'></i>",
    "Icon.Unlock": "<i class='fa fa-unlock'></i>",
    "Icon.Update": "<i class='fa fa-save'></i>",
    "Icon.Upload": "<i class='fa fa-upload'></i>",
    "Icon.Validate": "<i class='fa fa-exclamation-triangle'></i>",
    "Icon.View": "<i class='fa fa-eye'></i>",
    "Icon.ViewDetail": "<i class='fa fa-search-plus'></i>",
    "Icon.ViewHistory": "<i class='fa fa-history'></i>",
    "Account_Bank": "Account Bank",
    "Account_Branch": "Account Branch",
    "Account_Holder": "Account Holder",
    "Account": "Account",
    "AccountHolder": "Account Holder",
    "AccountID": "Account ID",
    "AccountingAmount": "Accounting Amount",
    "AccountLimit": "Account Limit",
    "AccountMerchant": "Account Merchant",
    "AccountMerchantID": "Account Merchant ID",
    "AccountNo": "Account No",
    "AccountNumber": "Account Number",
    "AccountOwner": "Account Owner",
    "AccountType": "Account Type",
    "AccountTypeCode": "Account Type Code",
    "Action": "Action",
    "ActionDateTime": "Action Date Time",
    "ActionMade": "Action Made",
    "ActionName": "Action Name",
    "ActionUserID": "Action User",
    "ActionType": "Action type",
    "Activate": "Activate",
    "Active": "Active",
    "Add": "Add",
    "Address": "Address",
    "AddressLine01": "Address Line 01",
    "AddressLine02": "Address Line 02",
    "AddressLine03": "Address Line 03",
    "AddUser": "Add User",
    "Administration": "Administration",
    "AID": "AID",
    "All": "All",
    "AllowCancel": "Allow Cancel",
    "AllowTimes": "Allow Times",
    "Alternative01AddressLine01": "Address Line 01",
    "Alternative01AddressLine02": "Address Line 02",
    "Alternative01AddressLine03": "Address Line 03",
    "Alternative01CityCode": "City Code",
    "Alternative01CountryCode": "Country Code",
    "Alternative01PostalCode": "Postal Code",
    "Alternative01StateCode": "State Code",
    "Alternative02AddressLine01": "Address Line 01",
    "Alternative02AddressLine02": "Address Line 02",
    "Alternative02AddressLine03": "Address Line 03",
    "Alternative02CityCode": "City Code",
    "Alternative02CountryCode": "Country Code",
    "Alternative02PostalCode": "Postal Code",
    "Alternative02StateCode": "State Code",
    "AmountApply": "Amount Applied",
    "AmountTxnDebt": "Amount Txn Debt",
    "AmountTxnSpos": "Amount Txn Spos",
    "Announce": "Announce",
    "APP": "APP",
    "Append": "Append",
    "Apply": "Apply",
    "Approve": "Approve",
    "ApproveAll": "Approve All",
    "ApproveCode": "Approve Code",
    "ApproveCodeRequired": "Please input Approve code",
    "Approved": "Approved (Success)",
    "ApprovedDescription": "Reason",
    "ApproveInstallment": "Approve Installment",
    "ApproveReconciliationDifferenceConfirm": "Do you want to approve Reconciliation Difference?",
    "AppVersion": "App version",
    "AssemblyName": "Assembly Name",
    "AuthenticationType": "Authentication Type",
    "AuthenticationTypeCode": "Authentication Type Code",
    "Authorised": "Authorised",
    "AutoSettlementDetail": "Auto Settlement Detail",
    "AutoSettlementManagement": "Auto Settlement Management",
    "AvailableBalance": "Available Balance",
    "AvgSale": "Avg Sale",
    "AvgTransaction": "Avg Transaction",
    "BadRequest": "BadRequest",
    "BalanceInquiry": "Balance Inquiry",
    "Bank": "Bank",
    "BankAcceptCancel": "Bank Accept Cancel",
    "BankAccount": "Bank Account",
    "BankAccountHolderName01": "Bank Account Holder Name 01",
    "BankAccountHolderName02": "Bank Account Holder Name 02",
    "BankAccountHolderName03": "Bank Account Holder Name 03",
    "BankAccountNumber01": "Bank Account Number 01",
    "BankAccountNumber02": "Bank Account Number 02",
    "BankAccountNumber03": "Bank Account Number 03",
    "BankApprove": "Bank Approve",
    "BankBranchName01": "Bank Branch Name 01",
    "BankBranchName02": "Bank Branch Name 02",
    "BankBranchName03": "Bank Branch Name 03",
    "BankCode": "Bank Code",
    "BankCode01": "Bank Code 01",
    "BankCode02": "Bank Code 02",
    "BankCode03": "Bank Code 03",
    "BankDeny": "Bank Deny",
    "BankID": "Bank ID",
    "BankMDRID": "MDR ID",
    "BankName": "Bank Name",
    "BankRoute": "Bank Route",
    "BankTransfer": "User ID Send",
    "BasicCardNumber": "Basic Card Number",
    "BatchNo": "Batch No",
    "Before": "Before",
    "BeforeInsert": "Before Insert",
    "BeforeUpdate": "Before Update",
    "BeneficiaryBank": "Beneficiary Bank",
    "BeneficiaryBranch": "Beneficiary Branch",
    "BeneficiaryMethod": "Beneficiary method",
    "BillAddress": "Address on Receipt",
    "BillingCycle": "Billing Cycle",
    "BillNumber": "Bill Number",
    "BillType": "Bill Type",
    "BINGroupCode": "BIN Group Code",
    "BirthDate": "Birth Date",
    "Branch": "Branch",
    "BranchAmount": "Branch Amount",
    "BranchApply": "Branch Apply",
    "BranchCode": "Branch Code",
    "BranchID": "Branch ID",
    "BranchInformation": "Branch Information",
    "BranchLevel": "Branch Level",
    "BranchManagement": "Branch Management",
    "BranchManager": "Branch Manager",
    "BranchName": "Branch Name",
    "BranchParent": "Parent Branch",
    "BranchPath": "Branch Path",
    "BranchShortName": "Branch Short Name",
    "BrandName": "Brand name",
    "BusinessLicenseType": "Type of Business",
    "BusinessType": "Business type",
    "Button.RequestUpdatePassword": "Send a request to change password",
    "CacheDetails": "Cache Details",
    "CacheID": "Cache ID",
    "CacheKey": "Cache key",
    "CacheManagement": "Cache Management",
    "CacheName": "Cache Name",
    "CacheType": "Cache type",
    "Cache": "Cache",
    "Calculate": "Calculate",
    "Callback": "Callback",
    "Cancel.1": "Cancel",
    "Cancel": "Cancel",
    "CancelRequest": "Request Cancel",
    "CannotUpdateRecordProcessing": "Cannot update because there are still pending records",
    "CapturePhoto": "Capture Photo",
    "Card": "Card",
    "CardAccount": "Card Account",
    "CardBIN": "Card BIN",
    "CardBINGroupCode": "Card BIN Group",
    "CardBrand": "CardBrand",
    "CardHolderName": "Card Holder Name",
    "CardLimit": "Card Limit",
    "CardNumber": "Card Number",
    "CardScheme": "Card Scheme",
    "CardStatus": "Card Status",
    "CardType": "Card Type",
    "CardUniqueID": "Card Unique ID",
    "CashBackAmount": "Cash Back Amount",
    "CashBackDate": "Cash Back Date",
    "CashID": "Cash ID",
    "CashName": "Cash Name",
    "CashTypeCode": "Cash Type Code",
    "Category": "Category",
    "CategoryManagement": "Category Management",
    "CCEmail": "CC Email",
    "ChangePassword": "Change Password",
    "Channel": "Channel",
    "ChannelID": "Channel ID",
    "ChartType": "Chart Type",
    "CIFNo": "CIF No",
    "City": "City",
    "CityCode": "City Code",
    "ClassName": "Class Name",
    "Clean": "Clean",
    "Clear": "Clear",
    "Close": "Close",
    "Collapse": "Collapse",
    "ComeBack": "Come Back",
    "Comment": "Comment",
    "CompanyName": "Business Name",
    "CompanyPhone": "Company Phone",
    "Complete": "Complete",
    "Confidence": "Confidence",
    "Confirm": "Confirm",
    "ConfirmNewPassword": "Confirm new password",
    "ConfirmPassword": "Confirm Password",
    "ConfirmPasswordMismatch": "Confirm password not match",
    "ConnectionName": "Connection Name",
    "ConsultantName": "Consultant Name",
    "ContainUnmatchRecord": "Contain unmatch record",
    "Content": "Content",
    "Contract": "Contract",
    "Copy": "Copy",
    "Correct": "Correct",
    "CountryCode": "Country Code",
    "CountTrxn": "Number of Transaction",
    "Create": "Create",
    "CreateDate": "Create Date",
    "CreateRefund": "Refund",
    "CreateRequest": "Create request",
    "Criteria": "Criteria",
    "CurrencyCode": "Currency Code",
    "CurrencyRate": "Currency Rate",
    "Current": "Current",
    "CustomDataSource": "Custom Data Source",
    "Customer": "Customer",
    "CustomerID": "Customer ID",
    "CustomerName": "Customer Name",
    "CustomerPaymentFeeCard": "% cutomer payment fee card",
    "CustomerPaymentFeeInstallment": "% customer payment fee installment",
    "CustomerType": "Customer Type",
    "CVMLimitJCB": "CVM Limit JCB",
    "CVMLimitMaster": "CVM Limit Master",
    "CVMLimitMC": "CVM Limit MC PAN",
    "CVMLimitVCCS": "CVM Limit VCCS",
    "CVMLimitVisa": "CVM Limit Visa",
    "DailyLimit": "Daily Limit",
    "DashboardNHPH": "Issue Bank",
    "DataAlreadyExists": "Data already exists",
    "DatabaseName": "Database Name",
    "DataSource": "Data Source",
    "DataType": "Data Type",
    "DateEnd": "Date End",
    "DateFrom": "From Date",
    "DateIssue": "Date Issue",
    "DateProcess": "Processing Date",
    "DateRange": "From date - To date",
    "DateStart": "Date Start",
    "DateTimeAction": "Date time action",
    "DateTimeActivate": "Date Time Activate",
    "DateTimeApprove": "Date Time Approved/Declined/Cancel",
    "DateTimeCancel": "Date Time Cancel",
    "DateTimeCreate": "Date Time Create",
    "DateTimeCreated": "Date Time Created",
    "DateTimeEffect": "Date time effect",
    "DateTimeExpire": "Date Time Expire",
    "DateTimeIssue": "Date Time Issue",
    "DateTimeLock": "Date Time Lock",
    "DateTimeModify": "Date Time Modify",
    "DateTimeQuantity": "Date Time Quantity",
    "DateTimeReprint": "Date Time Reprint",
    "DateTimeSend": "Date Time Send",
    "DateTo": "To Date",
    "DateType": "Search by date",
    "DateTypeSale": "Transaction Date Time",
    "DateTypeVoid": "Cancellation Date Time",
    "DBIssueBank": "Issue Bank",
    "DBMerchant": "Merchant",
    "DBTerminal": "Terminal",
    "DebitAccount": "Debit Account",
    "DebitAccountName": "Debit Account Name",
    "DebtAcc": "Debt Account",
    "DebtAmount": "Debt Amount",
    "DebtDateTime": "Debt Date Time",
    "DebtInformation": "Debt Information",
    "DebtPlanningID": "Debt Planning ID",
    "DebtReconciliation": "Debt Reconciliation",
    "Decision": "Decision",
    "DecisionFinal": "DecisionFinal",
    "Decline": "Decline",
    "DeclineRefundConfirm": "Do you want to decline Refund",
    "DefaultValue": "Default Value",
    "Delete": "Delete",
    "DeleteAll": "Delete All",
    "DeleteConfirm": "Do you want to delete this record?",
    "DeleteDate": "Delete Date",
    "DenyInstallment": "Deny Installment",
    "Description": "Description",
    "DestinationAccount": "Destination Account",
    "DestinationAccountNumber": "Destination Account Number",
    "DestinationBankCode": "Destination Bank Code",
    "DestinationBranchCode": "Destination Branch",
    "Detail": "Detail",
    "DevelopmentUnit": "Development Unit",
    "DevelopmentUnitAdd": "Add Development Unit",
    "DevelopmentUnitDetail": "Development Unit Detail",
    "DevelopmentUnitInfo": "Development Unit Info",
    "Device": "Device",
    "DeviceApprove": "Device Approve",
    "DeviceCategory": "Device Category",
    "DeviceCode": "Device Code",
    "DeviceImport": "Device Import",
    "DeviceSim": "Device Sim",
    "DeviceStatus": "Enroll Status",
    "DevUnitCode": "Development Unit Code",
    "DevUnitName": "Development Unit Name",
    "Disable": "Disable",
    "Disabled": "Disabled",
    "DisplayName": "Display Name",
    "Download": "Download",
    "DownloadFile": "Download file",
    "DownloadFileSample": "Download sample file",
    "DownloadFileCancel": "Download File Cancel",
    "DownloadFileNew": "Download File Installment",
    "DUPLICATE OR VALIDATE NOT SUCCESS": "DUPLICATE OR VALIDATE NOT SUCCESS",
    "Duplicate": "Duplicate",
    "DuplicateDataImport": "Duplicate Data Import",
    "DuplicateMCC": "The MCC, BankCode and the validity period have been declared to MDR. Duplicate data.",
    "DuplicateMCCAndEffectDate": "The MCC, BankCode and the validity period have been declared to MDR. Duplicate data.",
    "DuplicateMID": "The MID, BankCode and the validity period have been declared to MDR. Duplicate data.",
    "DuplicateMIDAndEffectDate": "Invalid format",
    "Effect": "Effect",
    "EffectDate": "Effect Date",
    "EffectDateGreaterCurrentDate": "Effect Date must be greater current date",
    "EffectDateGreaterCurrentMonth": "Effect Date must be greater current month",
    "EffectDateInvalid": "Invalid Effect Date",
    "EffectDateInvalid1": "Effect Date Invalid. Effect date cannot lower than declared effect date",
    "EFFECTED DATE VALIDATE NOT SUCCESS": "EFFECTED DATE VALIDATE NOT SUCCESS",
    "Effective": "Effective",
    "EffectMonth": "Effect Month",
    "EffectTime": "Effect Time",
    "Email": "Email",
    "EmailBank": "Email",
    "EmailLogin": "Email login",
    "EmbossingName": "Embossing Name",
    "EmptyTableMessage": "No data",
    "Enable": "Enable",
    "EndDateTime": "End date time",
    "EnglishName": "English Name",
    "EnrollDate": "Enroll Date",
    "EnterNewPassword": "Enter new password",
    "EnterOldPassword": "Enter old password",
    "Enum.AccountType.BC": "Collection Account",
    "Enum.AccountType.BE": "Expend Account",
    "Enum.AccountType.MA": "Master Account",
    "Enum.AccountType.MI": "Issue Account",
    "Enum.AccountType.PC": "Personal Account",
    "Enum.AccountType.PL": "Personal Loyalty Account",
    "Enum.BillType.Electricity": "Electricity",
    "Enum.BillType.Internet": "Internet",
    "Enum.BillType.Water": "Water",
    "Enum.BusinessLicenseType.BizHouseholdCert": "Household",
    "Enum.BusinessLicenseType.EnterpriseCert": "Company",
    "Enum.BusinessLicenseType.PersonalCert": "Personal",
    "Enum.CardRequestStatus.0": "New",
    "Enum.CardRequestStatus.1": "Approved",
    "Enum.CardRequestStatus.2": "Declined",
    "Enum.CardStatus.": "Un-Activated",
    "Enum.CardStatus.A": "Activated",
    "Enum.CardStatus.C": "Canceled",
    "Enum.CardStatus.E": "Expired",
    "Enum.CardStatus.L": "Locked",
    "Enum.CardType.C": "Credit Card",
    "Enum.CardType.D": "Debit Card",
    "Enum.CardType.P": "Prepaid Card",
    "Enum.CashBackStatus.0": "New",
    "Enum.CashBackStatus.1": "Approved",
    "Enum.CashBackStatus.2": "Declined",
    "Enum.CashType.CASH_BACK": "Cash Back",
    "Enum.CashType.DISCOUNT": "Discount",
    "Enum.CashType.TOP_UP": "Top Up",
    "Enum.CustomerPhotoEnum.IDENTITY_BACK": "Identity (Back)",
    "Enum.CustomerPhotoEnum.IDENTITY_FRONT": "Identity (Front)",
    "Enum.CustomerPhotoEnum.IDENTITY": "Identity",
    "Enum.CustomerPhotoEnum.SELFIE": "Selfie",
    "Enum.CustomerType.C": "Corporate",
    "Enum.CustomerType.P": "Personal",
    "Enum.FundTransferStatus.0": "New",
    "Enum.FundTransferStatus.1": "Approved",
    "Enum.FundTransferStatus.2": "Declined",
    "Enum.Gender.F": "Female",
    "Enum.Gender.M": "Male",
    "Enum.IDType.CC": "Citizenship card",
    "Enum.IDType.IC": "IC",
    "Enum.IDType.Passport": "Passport",
    "Enum.InstallmentStatus.0": "Inital",
    "Enum.InstallmentStatus.1": "Waiting for progressing",
    "Enum.InstallmentStatus.2": "Processing",
    "Enum.InstallmentStatus.3": "Processing cancel",
    "Enum.InstallmentStatus.4": "Wait for cancellation",
    "Enum.InstallmentStatus.5": "Canceled",
    "Enum.InstallmentStatus.6": "Completed",
    "Enum.InstallmentStatus.7": "Denied",
    "Enum.InstallmentStatus.8": "Waiting for gen file",
    "Enum.RefundStatus.1": "Create refund",
    "Enum.RefundStatus.2": "Waiting for refund",
    "Enum.RefundStatus.3": "VNPAY approved the refund",
    "Enum.RefundStatus.4": "VNPAY declined to refund",
    "Enum.RefundStatus.5": "Waiting Approval Not Enough Balance",
    "Enum.ResponseCode.0": "Processing",
    "Enum.ResponseCode.200": "Successful",
    "Enum.ResponseCode.400": "Bad Request",
    "Enum.ResponseCode.500": "Failure",
    "Enum.ResponseCode.504": "Timeout",
    "Enum.TransactionType.ACE": "Currency Exchange",
    "Enum.TransactionType.AFT": "Fund Transfer",
    "Enum.TransactionType.AIFT": "Interbank Fund Transfer",
    "Enum.TransactionType.CCB": "Cash Back",
    "Enum.TransactionType.CEP": "Purchase Ecommerce",
    "Enum.TransactionType.CLR": "Loyalty Point",
    "Enum.TransactionType.CPP": "Purchase POS",
    "Enum.TransactionType.CTU": "Top Up",
    "Enum.TransactionType.CTUB": "Top Up (Bonus)",
    "EReceipt": "E-Receipt",
    "EReceiptDetail": "E-Receipt Detail",
    "EReceiptSale": "E-Receipt Sale",
    "EReceiptSendIPNConfirm": "The invoice will be sent to the IPN system. Are you sure you want to proceed?",
    "EReceiptVOID": "E-Receipt Void",
    "Error": "Error",
    "ErrorDetail": "Error Detail",
    "ERROR_UPDATED": "Un successful because status of refund has been changed. Please check again!",
    "ERROR_SYSTEM": "System error",
    "EvaluationRecord": "Evaluation Record",
    "EWallet": "E-Wallet",
    "Exchange": "Exchange",
    "Exit": "Exit",
    "Expired": "Expired",
    "ExpiredPasswordTitle": "Password has expired on {$INTERPOLATION}, please update the password to continue using for SoftPOS Portal.",
    "ExpirePasswordDateTime": "Password expiration time: {$INTERPOLATION}",
    "Export": "Export",
    "ExportCsv": "Export to Csv",
    "ExportExcel": "Export to Excel",
    "ExportMerchantInfo": "Export merchant information",
    "ExportZip": "Export Zip",
    "F22POSEntryMode": "POS Entry Mode",
    "F38AuthorizationResponse": "F38AuthorizationResponse",
    "Fail": "Approved (Fail)",
    "Failed": "Failed",
    "Fee": "Fee",
    "FeeCode": "Fee Code",
    "FeeCommon": "Common Fee",
    "FeeCurrencyCode": "Fee Currency Code",
    "FeeDifference": "Fee Difference",
    "FeeMode": "Fee Mode",
    "FeeMDRMerchantMO": "Fee MDR merchant MO",
    "FeeMDRMerchantMODetail": "Fee MDR merchant MO detail",
    "FeeProgressive": "Progressive Fee",
    "FeePercent": "Fee percent",
    "FeeType": "Fee Type",
    "Female": "Female",
    "FieldGroup": "Field Group",
    "FieldID": "Field ID",
    "FieldLabel": "Field Label",
    "FieldName": "Field Name",
    "FieldText": "Field Text",
    "FieldValue": "Field Value",
    "FileCreateDate": "File created date",
    "FileName": "File Name",
    "FileNotSend": "Not send",
    "FileProcessingStatus": "File processing status",
    "FileSent": "Sent",
    "FileSentDate": "File sent date",
    "FileStatus": "File status",
    "Filter": "Filter",
    "FirstName": "First Name",
    "Fixed": "Fixed",
    "FixedCharge": "Fixed charge",
    "FlagDescription": "FlagDescription",
    "FlagName": "FlagName",
    "Footer": "Footer",
    "ForgotPassword": "Forgot password",
    "ForgotPasswordNotification": " In case you forget your password, we will send an email to your registered email address to reset your password. Please click the link in the email and follow the instructions to reset a new password.",
    "FromAddress": "From Address",
    "FullName": "Full Name",
    "FullRefund": "Full Refund",
    "FunctionName": "Function Name",
    "FunctionalName": "Functional name",
    "FundTransferRequestID": "Fund Transfer Request ID",
    "Gender": "Gender",
    "GenFile": "Gen File",
    "HAD_INSTALLMENT": "Had installment transaction",
    "HardwareInfo": "Hardware Information",
    "HasAccount": "Has Account",
    "HaveEReceipt": "E-Receipt",
    "Header": "Header",
    "HeadOffice": "Head Office",
    "HeadOfficeAddress": "Head office address",
    "Help.Customer": "CIF No/Customer ID/Phone Number",
    "HomePhone": "Home Phone",
    "HostBranch": "Host Branch",
    "IC": "IC",
    "ICBackImage": "IC Back Image",
    "ICFrontImage": "IC Front Image",
    "ID": "ID",
    "Identity": "Identity",
    "IDNo": "ID No",
    "IDType": "ID Type",
    "Ignore": "Ignore",
    "ImgFull": "Image Full",
    "Import": "Import",
    "ImportedFile": "Imported files",
    "ImportReconciliationFile": "Import Reconciliation File",
    "Inactive": "Inactive",
    "Incorrect": "Incorrect",
    "Ineffective": "Expired",
    "Information": "Information",
    "InputType": "Input Type",
    "Inquiry": "Inquiry",
    "Insert": "Insert",
    "InsertInstallment": "Insert Installment",
    "InstallmentAllow": "Allow installment",
    "Installment": "Installment",
    "InstallmentJoin": "Join installment",
    "InstallmentAmount": "Installment Amount",
    "InstallmentBank": "Installment Bank",
    "InstallmentCreate": "Installment create",
    "InstallmentFeeBank": "Installment fee bank",
    "InstallmentFeeMerchantDetail": "Fee merchant detail",
    "InstallmentFeeMerchant": "Insatllment merchant fee management",
    "InstallmentID": "Installment ID",
    "InstallmentManagement": "installment management",
    "InstallmentRequest": "Installment request",
    "InstallmentRequestDetail": "Installment request detail",
    "InstallmentRequestManagement": "Installment request management",
    "InstallmentRefundDebtErrorCode": "Installment refund debt error code",
    "InstallmentStatus": "Installment Status",
    "InstallmentView": "Installment view",
    "InstallMinimum": "Install Minimum",
    "InstBankFee": "InstBankFee",
    "InstDevUnitFee": "InstDevUnitFee",
    "InstMerchantFee": "InstMerchantFee",
    "InternalServerError": "Internal server error",
    "InvalidAction": "Invalid Action.",
    "InvalidBankCode": "Invalid BankCode.",
    "InvalidCurrency": "Invalid Currency.",
    "InvalidEffectDate": "Invalid EffectDate.",
    "InvalidFileData": "Invalid file data",
    "InvalidLocalBank": "Invalid Local Bank.",
    "InvalidLocalJCB": "Invalid Local JCB.",
    "InvalidLocalMaster": "Invalid Local Master.",
    "InvalidLocalUnionPay": "Invalid Local Union Pay.",
    "InvalidLocalVisa": "Invalid Local Visa.",
    "InvalidMCC": "Invalid MCC.",
    "InvalidMID": "Invalid MID.",
    "InvalidMIDAndMCC": "Invalid MID and MCC.",
    "InvalidNewPassword": "The new password must not match the 5 most recent passwords, please check again.",
    "InvalidOldPassword": "Old password invalid",
    "InvalidOtherJCB": "Invalid Other JCB.",
    "InvalidOtherMaster": "Invalid Other Master.",
    "InvalidOtherUnionPay": "Invalid Other Union Pay.",
    "InvalidOtherVisa": "Invalid Other Visa.",
    "InvalidStatus": "Invalid status",
    "InvalidTransaction": "Invalid Transaction :",
    "InvalidTransactionToCreateInstallmentRequest": "Invalid transaction to create installment request",
    "InvalidUsernameOrPassword": "Login failed. Incorrect username or password.<br/><strong>Note:</strong><br/>The password is case-sensitive.",
    "InvalidVNBank": "Invalid VN Bank.",
    "InvalidVNJCB": "Invalid VN JCB.",
    "InvalidVNMaster": "Invalid VN Master.",
    "InvalidVNUnionPay": "Invalid VN UnionPay.",
    "InvalidVNVisa": "Invalid VN Visa.",
    "InvoiceAddress": "Invoice Address",
    "InvoiceNumber": "Invoice Number",
    "IPLogin": "IP login",
    "IsAllowDelete": "Is Allow Delete",
    "IsAllowExport": "Is Allow Export",
    "IsAllowInsert": "Is Allow Insert",
    "IsAllowTopup": "Is Allow Top Up",
    "IsAllowUpdate": "Is Allow Update",
    "IsAuthenticateCustomer": "Is Authenticate Customer",
    "IsBasicCard": "Is Basic Card",
    "IsCorporateCard": "Is Corporate Card",
    "IsCorporateCustomer": "Is Corporate Customer",
    "IsDisable_ext": "IsDisable",
    "IsDisable_pos": "IsDisable",
    "IsDisable": "Is Disable",
    "IsDisableOnInsert": "Is Disable On Insert",
    "IsDisableOnUpdate": "Is Disable On Update",
    "IsFixAmount": "Is Fix Amount",
    "IsHeadOffice": "Is Head Office",
    "IsIdentifyCustomer": "Customer Identifier?",
    "IsInstallment": "Is Installment",
    "IsInUse": "Is In Use",
    "IsLazyLoad": "Is Lazy Load",
    "IsLogical": "Is Logical",
    "IsOnline": "Is Online",
    "IsProgressiveFee": "Is Progressive Fee",
    "IsRegisterInstallment": "Is Register Installment",
    "IsRequire": "Is Require",
    "IsReversal": "Is Reversal",
    "IsRunTime": "Is Run Time",
    "IsSettlement": "Is Settlement",
    "IssueBank": "Issue Bank",
    "IsUseDefaultLimit": "Is Use Default Limit",
    "IsUseOnce": "Is Use Once",
    "IsUseReceipt": "Is use receipt",
    "IsBlockInternationalCard":"Block International Card",
    "Items": "Items",
    "Keyword": "Keyword",
    "LanguageCode": "Language Code",
    "LastDigitsCardNumber": "Card Number (Last 4 digits)",
    "LastEditPasswordDateTime": "Last password change time: {$INTERPOLATION}",
    "LastLoginDate": "Last Login Date",
    "LastName": "Last Name",
    "Latitude": "Latitude",
    "lblIsSettlement": "lblIsSettlement",
    "lblSoftPOSUser": "lblSoftPOSUser",
    "LDAPCommonName": "LDAP Common Name",
    "LineManager": "Line Manager",
    "ListMerchantID": "List Merchant ID",
    "ListPolicy": "ListPolicy",
    "Load": "Load",
    "LocalBank": "Local Bank",
    "LocalBankFix": "Local Bank Fix",
    "LocalDateTime": "Local Date Time",
    "LocalJCB": "Local JCB",
    "LocalJCBFix": "Local JCB Fix",
    "LocalMaster": "Local Master",
    "LocalMasterFix": "Local Master Fix",
    "LocalName": "Local Name",
    "LocalUnionPay": "Local Union Pay",
    "LocalUnionPayFix": "Local Union Pay Fix",
    "LocalVisa": "Local Visa",
    "LocalVisaFix": "Local Visa Fix",
    "Lock": "Lock",
    "Locked": "Locked",
    "LogAction": "Log Action",
    "LogDetail": "Log Detail",
    "LogID": "Log ID",
    "LogInformation": "Log Information",
    "LoginWith": "LOGIN WITH",
    "LogMessage": "Log Message",
    "Logo": "URL Logo",
    "LogoBank": "Logo",
    "LogManagement": "Log Management",
    "LogLoginManagement": "Log login management",
    "LogDataConfigurationManagement":"Log data configuration management",
    "LogFunctionalManagement": "Log functional management",
    "Longitude": "Longitude",
    "MailCC": "CC",
    "MailTitle": "Title",
    "Male": "Male",
    "ManualPanLimit_Visa": "Manual Pan Limit Visa",
    "ManualPANLimitJCB": "Manual PAN Limit JCB",
    "ManualPANLimitMaster": "Manual PAN Limit Master",
    "ManualPANLimitVCCS": "Manual PAN Limit VCCS",
    "ManualPANLimitVisa": "Manual PAN Limit Visa",
    "ManualSettlementDetail": "Manual Settlement Detail",
    "ManualSettlementManagement": "Manual Settlement Management",
    "MasterAccountNumber": "Master Account Number",
    "MaxAmount": "Maximum Amount",
    "MaximumLatePaymentDays": "Maximum Late Payment Days",
    "MaximumPaymentDays": "Maximum Payment Days",
    "MCC NOT EXISTS": "MCC NOT EXISTS",
    "MCC": "MCC",
    "MCCInfo": "MCC Info",
    "MDRBank": "Bank MDR Inquiry",
    "MDRBankAdd": "Add MDR Bank",
    "MDRBankApprove": "MDR Bank Approve",
    "MDRBankFee": "MDR Bank - Fee",
    "MDRBankImport": "Bank MDR Import",
    "MDRBankInformation": "MDR Bank Information",
    "MDRBankFeeCalculateConfirmation": "Do you want to calculate MDR Bank Fee",
    "MDRCode": "MDR Code",
    "MDRDescription": "MDR Description",
    "MDRMerchant": "Merchant MDR Inquiry",
    "MDRMerchantAdd": "Merchant MDR Add",
    "MDRMerchantApprove": "Merchant MDR Approve",
    "MDRMerchantFee": "Merchant MDR Fee",
    "MDRMerchantFeeCalculateConfirmation": "Do you want to calculate MDR Merchant Fee",
    "MDRMerchantID": "MDRMerchant ID",
    "MDRMerchantImport": "Merchant MDR import",
    "MDRMerchantInformation": "Merchant MDR Information",
    "MDRPercent": "MDR Percent",
    "MDRStatus": "MDR Status",
    "MDRType": "MDR Type",
    "Merchant_EstimatedPay": "Merchant Estimated Pay",
    "Merchant_Pay": "Merchant Pay",
    "Merchant": "Merchant",
    "MerchantCode": "MID",
    "MerchantDiscountRate": "MERCHANT DISCOUNT RATE",
    "MerchantExportByMOMerchantIDs": "Export merchant information by MO IDs",
    "MerchantGUID": "Merchant GUID",
    "MerchantID": "Merchant ID",
    "MerchantInfo": "Merchant Info",
    "MerchantManagement": "Merchant Management",
    "MerchantName": "Merchant Name",
    "MerchantOwned": "MerchantOwned",
    "MerchantType": "Merchant Type",
    "Message.AccessDenied": "You do not have access to view this page within the site.",
    "Message.AccountOrCard": "Account Number/Card Number",
    "Message.ActionFail": "{$INTERPOLATION} Failed. {$INTERPOLATION_1}",
    "Message.ActionSuccess": "{$INTERPOLATION} Successful.",
    "Message.ActivateAccountConfirm": "Are you sure you want to active this user?",
    "Message.All": "All",
    "Message.ApproveSuccess": "Request has been approved",
    "Message.AreYouSureWantToDeleteRecord": "Are you sure want to delete record?",
    "Message.AreYouSureYouWantToApprove": "Are you sure you want to approve?",
    "Message.AreYouSureYouWantToContinue": "Are you sure you want to continue?",
    "Message.AreYouSureYouWantToDELETE": "Are you sure you want to DELETE?",
    "Message.BankRequired": "The bank field is required",
    "Message.CardBalanceNotEnough": "Card does not has enough balance.",
    "Message.CardHasLinked": "Card has been linked",
    "Message.CardStatusInvalid": "Card status invalid.",
    "Message.ChangePasswordSuccess": "Change password success",
    "Message.ChooseTitle": "Roles by Title",
    "Message.ChooseXLSXFile": "Please choose XLSX file.",
    "Message.ComebackLater": "Please come back later",
    "Message.ConfidenceLow": "Low Confidence",
    "Message.ConfirmRefundApprove": "Are you sure want to approve the refund?",
    "Message.ContactAdministrator": "Please retry or contact administrator",
    "Message.ContentNoAuthentication": "You are paying without PIN or password. Click <strong>Confirm</strong> button to finish payment. Otherwise, click <strong>Cancel.</strong>",
    "Message.ContentSMS": "This number is valid for 5 minutes.<br /> If it runs out, you can click <b>Send SMS</b> button to receive a new OTP.",
    "Message.ContentSoftToken": "Remember that the token is only valid for 30 seconds.<br />If it runs out, try to enter other token 'cause it maybe expire.",
    "Message.DataNotFound": "Not found any data.",
    "Message.DeclineSuccess": "Request has been declined",
    "Message.DeleteFail": "Delete Failed. {$INTERPOLATION}",
    "Message.DeleteRecordFail": "Delete Failed. {$INTERPOLATION}",
    "Message.DeleteRecordSuccessfully": "Delete record successfully",
    "Message.DeleteSuccess": "Delete Successful.",
    "Message.DisableAccountConfirm": "Are you sure you want to disable this user?",
    "Message.DisableFail": "Disable Failed. {$INTERPOLATION}",
    "Message.DisableSuccess": "Disable Successful.",
    "Message.EmptyFile": "Empty file.",
    "Message.EnableFail": "Enable Failed. {$INTERPOLATION}",
    "Message.EnableSuccess": "Enable Successful.",
    "Message.Error403": "ACCESS FORBIDDEN",
    "Message.ExceedProductLimit": "Exceed Product Limit",
    "Message.Failure": "Failure",
    "Message.FailureAction": "Action Failed. {$INTERPOLATION}",
    "Message.FailureUploadFile": "There was an error in the file upload.",
    "Message.FailureTransaction": "Invalid transaction. Please check again.",
    "Message.FileNameAlreadyExists": "File Name Already Exists.",
    "Message.ForgotPassword": "Instructions for resetting your password have been sent to your email.",
    "Message.FundTransferFail": "Fund Transfer Failed. {$INTERPOLATION}",
    "Message.FundTransferInformation": "Fund Transfer Information",
    "Message.FundTransferSuccess": "Fund Transfer Successful.",
    "Message.HeaderNoAuthentication": "No Authentication",
    "Message.HeaderSMS": "Enter OTP here",
    "Message.HeaderSoftToken": "Enter Token here",
    "Message.IncorrectUsername": "The username is incorrect, please check again.",
    "Message.InsertFail": "Insert Failed. {$INTERPOLATION}",
    "Message.InsertSuccess": "Insert Successful.",
    "Message.InternalServerError": "Internal server error",
    "Message.InvalidAction": "Invalid Action.",
    "Message.InvalidCompareDate": "Invalid Effect Date. The effective date must be greater than the creation date and approval date.",
    "Message.InvalidData": "Data Invalid",
    "Message.InvalidDate": "Invalid date format",
    "Message.InvalidDateOver31Days": "The search interval needs to be less than or equal to 31 days",
    "Message.InvalidDateOver31Days.1": "Invalid selected date time (>31 days), please re-check information",
    "Message.InvalidEmail": "Invalid email format",
    "Message.InvalidField": "{$INTERPOLATION} is invalid.",
    "Message.InvalidFieldLength": "The length of {$INTERPOLATION} is invalid. Minimum = {$INTERPOLATION_1}, Maximum = {$INTERPOLATION_2}",
    "Message.InvalidFileData": "Invalid file data. Please check again.",
    "Message.InvalidFileSize": "Invalid file size.",
    "Message.InvalidFileType": "Invalid file extension.",
    "Message.InvalidMax": "Maximum value is {$INTERPOLATION}",
    "Message.InvalidMaxLength": "Maximum {$INTERPOLATION} character",
    "Message.InvalidMin": "Minimum value is {$INTERPOLATION}",
    "Message.InvalidMinLength": "Minimum {$INTERPOLATION} character",
    "Message.InvalidMonth": "Invalid month",
    "Message.InvalidSelectedTime": "The selected time period is invalid, please check the information",
    "Message.InvalidSettlementHours": "The field of automatic accounting hours must not be left empty.",
    "Message.InvalidTransactionToCreateInstallment": "Invalid transaction to create installment",
    "Message.InstallmentCreateSuccess": "Installment create successfully.",
    "Message.InstallmentCreateFail": "Installment create fail, please check again.",
    "Message.InvalidActionAndTryAgain": "Invalid action, please check again",
    "Message.LackOfFunds": "Lack Of Funds",
    "Message.LockedAccountNeedContacting": "This account is temporarily locked. Please contact the administration for assistance.",
    "Message.MaxDate": "Max date must be less than",
    "Message.MerchantIDRequired": "The merchant id field is required",
    "Message.MIDNotExist": "MID is invalid",
    "Message.MinDate": "Min date must greater",
    "Message.MoveBranchDeviveError": "The device cannot be transferred to another merchant, please check the information before updating",
    "Message.MoveBranchTerminalError": "This terminal has been set up for device, please check the information before updating",
    "Message.NewPasswordMatchOldPassword": "The new password must not match the old password, please check again.",
    "Message.No": "No",
    "Message.NotFoundFileData": "Not Found File Data.",
    "Message.NotFoundText": "No items found",
    "Message.NotUse": "Not Use",
    "Message.NoWhitespace": "No Whitespace",
    "Message.NumDateNotExceeded": "Number of date can not be exceeded",
    "Message.OnlyEnterMCCOrMIDBank": "Searching by only one MCC or MID Bank.",
    "Message.OTPGuide": "Please get OTP number on your phone.",
    "Message.OTPVerifyFailure": "Your token is invalid",
    "Message.OTPVerifySuccess": "Your token is valid",
    "Message.OverSizeFileUpload": "Upload file is too large. Please check again.",
    "Message.PasswordManagement": "Password Management",
    "Message.PasswordPolicyNotMatch": "New password does not match policy",
    "Message.PaymentFail": "Payment Failed. {$INTERPOLATION}",
    "Message.PaymentSuccess": "Payment Successful.",
    "Message.PleaseEnterReversalPurpose": "Please input value for Reason.",
    "Message.PleaseSelectFileXlsx": "Please select file xlsx.",
    "Message.ReasonRequired": "The reason field is required",
    "Message.ReceiverInformation": "Receiver Information",
    "Message.ReconciliationApproveFailure": "An error occurred while approving, please try again.",
    "Message.ReconciliationApproveSuccess": "Send request debt success.",
    "Message.ReconciliationRejectFailure": "Reconciliation Reject Failure.",
    "Message.ReconciliationRejectSuccess": "Reconciliation Reject Success.",
    "Message.RefundResendDebtFailed": "Refund Resend Debt Failed.",
    "Message.RefundRevertFailed": "Refund Revert Failed.",
    "Message.ReportCriteriaInformation": "Report Criteria",
    "Message.ReportDetailInformation": "Report Detail",
    "Message.ReportSummaryInformation": "Report Summary",
    "Message.required": "Field is required",
    "Message.RequiredFail": "Please fill in required fields",
    "Message.RequireKYC": "Please finish KYC process",
    "Message.ResetPasswordConfirm": "Are you sure you want to reset password this user?",
    "Message.ResetPasswordSuccess": "Password reset instructions have been sent to your email.",
    "Message.ReversalFail": "Transaction Reversal Failed. {$INTERPOLATION}",
    "Message.ReversalSuccess": "Transaction Reversal Successful.",
    "Message.RoleInformation": "Role Information",
    "Message.SearchInformation": "Search Information",
    "Message.SectionAccountInformation": "Account Information",
    "Message.SectionAlternativeAddress": "Alternative Address",
    "Message.SectionAuthentication": "Authentication Information",
    "Message.SectionBankAccountInformation": "Bank Account Information",
    "Message.SectionBusinessInformation": "BUSINESS INFORMATION",
    "Message.SectionCardDetail": "Card Information",
    "Message.SectionCardHistory": "Card History",
    "Message.SectionCardInquiry": "Card Inquiry",
    "Message.SectionCardPayment": "Payment Information",
    "Message.SectionCardTopup": "Top Up Information",
    "Message.SectionCompanyAddress": "Company Address",
    "Message.SectionContactInformation": "Contact Information",
    "Message.SectionCustomerInformation": "Customer Information",
    "Message.SectionPersonalInformation": "Personal Information",
    "Message.SectionPrimaryAddress": "Primary Address",
    "Message.SectionProcessInformation": "Fund Transfer Process",
    "Message.SectionRegistrationInformation": "REGISTRATION INFORMATION",
    "Message.SectionRequestInformation": "Request Information",
    "Message.SectionReversalInformation": "Reversal Information",
    "Message.SectionTransactionHistory": "Transaction History",
    "Message.SectionTransactionInformation": "Transaction Information",
    "Message.SenderInformation": "Sender Information",
    "Message.SendRequestApproveSuccess": "Send request approve success.",
    "Message.SendRequestFailure": "Send request failure",
    "Message.SendRequestSuccess": "Send request successful",
    "Message.ServerError": "System Error.",
    "Message.StatusChanged": "Another user have just updated data.",
    "Message.SuccessAction": "Action Successful.",
    "Message.Successful": "Successful",
    "Message.SystemErrorReTry": "System is down. Please re-try again.",
    "Message.TabBranch": "Branch Information",
    "Message.TabDevice": "Device Information",
    "Message.TabDeviceInfo": "App Account Information",
    "Message.TabEReceiptInfo": "EReceipt Information",
    "Message.TabGeneral": "General Information",
    "Message.TabHistory": "History Information",
    "Message.TabImage": "Image Info",
    "Message.TabMCC": "MCC Info",
    "Message.TabMerchant": "Merchant Information",
    "Message.TabRefund": "Refund Information",
    "Message.TabSetting": "Settings",
    "Message.TabTerminal": "Terminal Information",
    "Message.TheSystemOnlySupportsXlsxFileFormat": "The system only supports xlsx file format.",
    "Message.TokenInvalid": "Token invalid",
    "Message.TopupFail": "Top Up Failed. {$INTERPOLATION}",
    "Message.TopupSuccess": "Top Up Successful.",
    "Message.Total": "Total",
    "Message.TransactionNotExist": "Transaction Not Exist.",
    "Message.TransactionNotSettledReCheck": "The transaction not settled, please check again.",
    "Message.UnderMaintenance": "Under maintenance",
    "Message.UpdateFail": "Update Failed. {$INTERPOLATION}",
    "Message.UpdateMDRMerchantConfirm": "Do you want to update MDR Merchant information?",
    "Message.UpdatePasswordNotificationUser": "Instructions for resetting the password have been sent to the user's email.",
    "Message.UpdatePasswordSuccess": "Update password successful",
    "Message.UpdateReconciliationSuccess": "Update reconciliation file successfully",
    "Message.UpdateRefundFailure": "Action fail, because Refund has been changed to another state! Please check again.",
    "Message.UpdateSuccess": "Update Successful.",
    "Message.UploadFileSuccess": "Upload File Success.",
    "Message.WaitForApproval": "Your request is reviewing. Please come back later.",
    "Message.Yes": "Yes",
    "MID AND BANKCODE NOT EXISTS": "MID AND BANKCODE NOT EXISTS",
    "MID": "MID",
    "MIDBank": "MID Bank",
    "MIDNotBelongsToBank": "MID does not belongs to bank",
    "MIDOrTID": "MID/TID",
    "MinimumAmount": "Minimum Amount",
    "MinLength": "Min Length",
    "MistakenAccountingNo": "Mistaken Accounting No",
    "MMC": "MMC",
    "MMDR_FeeDifference": "MMDR_FeeDifference",
    "MMDR_Fix": "MMDR_Fix",
    "MMDR_Lastupdated": "MMDR_Lastupdated",
    "MMDR_percent": "MMDR_percent",
    "MMDR_TempFix": "MMDR_TempFix",
    "MMDR_TempPercent": "MMDR_TempPercent",
    "Mobile": "Mobile",
    "Model_pos": "Model",
    "Model": "Model",
    "ModelBill": "Model Bill",
    "MOFeeID": "MO Fee ID",
    "MOMerchantID": "MO Merchant ID",
    "Month": "Month",
    "MonthExpire": "Month Expire",
    "MOShopID": "MO Shop ID",
    "MOTerminalID": "MO Terminal ID",
    "Nationality": "Nationality",
    "NeedMore": "Need more",
    "New": "New",
    "NewValue": "New value",
    "NewPassword": "New Password",
    "No": "No",
    "NodeRootOfMerchant": "Node Root Of Merchant",
    "NotAction": "No Action",
    "Note": "Note",
    "Notice": "Notice",
    "NotAuthenticated": "User has not been granted any roles. Please contact Administrator for authorization.",
    "NumberCancel": "Number of Cancellation Request",
    "NumberNew": "Number of Installment Request",
    "NumberOfTxn": "Number Of Txn",
    "NumberOfTxnSuccess": "Number of successful transactions",
    "NumberOfTxnUnsuccess": "Number of Unsuccessful transactions",
    "NumberProgressiveFee": "Number Of Progressive Fee",
    "NumberTxnDebt": "Number Txn Debt",
    "NumberTxnSpos": "Number Txn Spos",
    "NumOfTransactionPoint": "Num Of Transaction Point",
    "ObjectID": "Object ID",
    "ObjectType": "Module",
    "OffchainCode": "Offchain code",
    "OK": "OK",
    "OldPassword": "Old Password",
    "OldValue": "Old Value",
    "OnClientChange": "On Client Change",
    "OpeningAmount": "Opening Amount",
    "OpeningBalanceAmount": "Opening Balance Amount",
    "OrderNumber": "NO",
    "ORGTransID": "ORG Trans ID",
    "OriginalTransactionCreatedDate": "Original Transaction Created Date",
    "OriginalTransactionID": "Original Transaction ID",
    "OtherBank": "Other Bank",
    "OtherInformation": "Other Information",
    "OtherJCB": "Other JCB",
    "OtherJCBFix": "Other JCB Fix",
    "OtherMaster": "Other Master",
    "OtherMasterFix": "Other Master Fix",
    "OtherReversalPurpose": "Other Reason",
    "Others": "Others",
    "OtherUnionPay": "Other Union Pay",
    "OtherUnionPayFix": "Other Union Pay Fix",
    "OtherVisa": "Other Visa",
    "OtherVisaFix": "Other Visa Fix",
    "Overwrite": "Overwrite",
    "Page": "Page",
    "ParentID": "Parent Branch",
    "ParentIDOrDevelopmentUnit": "ParentID / DevelopmentUnit",
    "ParentOfMerchant": "Parent Of Merchant",
    "PartialRefund": "Partial Refund",
    "PartnerID": "Partner ID",
    "PartnerUserID": "Partner User ID",
    "Password": "Password",
    "PasswordChangeNotice": "Password consists of 8-64 characters, including at least 1 number, 1 letter, 1 special character and must not overlap with the 5 most recent passwords",
    "Payment": "Payment",
    "PaymentBank": "Payment Bank",
    "PaymentSettled": "Payment settled",
    "PaymentTypeAccount": "Payment Type Account",
    "PaymentTypeAccounting": "Payment",
    "PaymentWalletNumber": "Payment Wallet Number",
    "PercentAmount": "Percent Amount",
    "PermissionLog": "Permission Log",
    "PhoneExtension": "Phone Extension",
    "PhoneNumber": "Phone Number",
    "PINNumber": "PIN Number",
    "PlaceHolder.Customer": "CIF No/Customer ID/Phone Number",
    "PlaceHolder": "Place Holder",
    "PlaceIssue": "Place Issue",
    "PlaceOfProduction": "Place Of Production",
    "PointID": "Point ID",
    "PointName": "Point Name",
    "PopupReconciliationReject": "Reason for refusal to approve",
    "PortraitImage": "Portrait Image",
    "POSAmount": "POS Amount",
    "POSEntryMode": "POS Entry Mode",
    "POSID": "POS ID",
    "POSInformation": "POS Information",
    "POSManagement": "POS Management",
    "POSName": "POS Name",
    "POSOrSID": "POS ID",
    "PostalCode": "Postal Code",
    "PrefixName": "Prefix Name",
    "PrimaryKey": "Primary Key",
    "PrimaryPhoneNumber": "Primary Phone Number",
    "Print": "Print",
    "Procedure": "Procedure",
    "Process": "Process",
    "Processed": "Processed",
    "Processing": "Processing",
    "ProcessReason": "Reason Approve/Reject/Cancel",
    "ProcessResult": "Process result",
    "ProcessStatusOfReconciliationIsNotTimeOut": "Process status of reconciliation is not TimeOut",
    "ProcessType": "Process Type",
    "Product": "Product",
    "ProductApply": "Product Apply",
    "ProductBIN": "Product BIN",
    "ProductCode": "Product Code",
    "ProductID": "Product ID",
    "ProductImage": "Product Image",
    "ProductLimit": "Product Limit",
    "ProductName": "Product Name",
    "ProductSchemeCode": "Product Scheme Code",
    "ProductType": "Product Type",
    "ProductTypeCode": "Product Type Code",
    "Progressive": "Progressive",
    "ProgressiveBy": "Progressive By",
    "Promotion": "Promotion",
    "PromotionDescription": "Promotion Description",
    "PromotionID": "Promotion ID",
    "PromotionName": "Promotion Name",
    "PwdRuleChar": "Least 1 character",
    "PwdRuleLength8And64": "From 8 to 64 characters",
    "PwdRuleNum": "Least 1 number character",
    "PwdRuleSpec": "Least 1 special character. Example: !@#$%^*()”",
    "QRCode": "QR Code",
    "Quantity": "Quantity",
    "Race": "Race",
    "Range": "Range",
    "Re-Enroll": "Re-Enroll",
    "ReceiptEmailEmpty": "There is no email information, please click on the invoice image link to download it.",
    "ReceiptFullImageEmpty": "Transaction doesn't have image receipt data, please contact the administration for assistance.",
    "ReceiptID": "ReceiptID",
    "ReceiptType": "Receipt Type",
    "Receive": "Receive",
    "ReceiveAmount": "Receive Amount",
    "Receiver": "Receiver",
    "ReceiverAccount": "Receive Account",
    "RecNo": "Rec No",
    "Reconciliation": "Reconciliation",
    "ReconciliationCode": "Reconciliation Code",
    "ReconciliationDate": "Reconciliation Date",
    "ReconciliationDateFrom": "Reconciliation Date From",
    "ReconciliationDateTo": "Reconciliation Date To",
    "ReconciliationDetail": "Reconciliation Detail",
    "ReconciliationDifferenceList": "Reconciliation Difference List",
    "ReconciliationFile": "Reconciliation File",
    "ReconciliationID.1": "Reconciliation ID",
    "ReconciliationID": "Reconciliation ID",
    "ReconciliationManagement": "Reconciliation Management",
    "ReconciliationProcess.1": "Additional accounting",
    "ReconciliationProcess.2": "Revert transaction",
    "ReconciliationProcess.3": "Untreated",
    "ReconciliationProcess.4": "Handling at the debt system",
    "ReconciliationResult.1": "Correct",
    "ReconciliationResult.2": "SOFTPOS yes, Branch no",
    "ReconciliationResult.3": "SOFTPOS no, Branch yes",
    "ReconciliationResult.4": "Amount wrong",
    "ReconciliationResult.5": "Account number Wrong",
    "ReconciliationResult": "Reconciliation result",
    "ReconciliationStatus": "Reconciliation Status",
    "ReconciliationTransactionDetail": "Reconciliation Transaction Detail",
    "ReconciliationWillBeUpdateNextTime": "The reconciliation file will be updated again in the next session",
    "Reference": "Reference",
    "ReferenceNumber": "Reference Number",
    "Refresh": "Refresh",
    "Refund": "Refund",
    "RefundAmount": "Refund Amount",
    "RefundAvailable": "Refund Available",
    "RefundDateTime": "Date Time Refund",
    "RefundDateTimeCreate": "Refund Date Time Create",
    "RefundDescription": "Refund Description",
    "RefundDetail": "Refund Detail",
    "RefundID": "Refund ID",
    "RefundManagement": "Refund Management",
    "RefundPurpose": "Refund Reason",
    "RefundStatus": "Refund Status",
    "RefundStatusApprove": "Refund Status Approve",
    "RefundType": "Refund Type",
    "RefundTypeAccounting": "Refund",
    "RefuseTypeAccounting": "Refuse",
    "RegID": "Registration ID",
    "RegInfo": "Registration Info",
    "RegionID": "Region ID",
    "Register": "Register",
    "RegisterNew": "Register New",
    "RegisterNewMerchant": "New merchant/General branch",
    "RegT2PUserID": "RegT2PUserID",
    "Reject": "Reject",
    "RejectAll": "Reject All",
    "RejectDescription": "Reject Description",
    "Religion": "Religion",
    "Reload": "Reload",
    "ReloadAll": "Reload All",
    "ReloadCache": "Reload Cache",
    "Remark": "Remark",
    "Remove": "Remove",
    "RemoveCache": "Remove Cache",
    "RemoveTerminalFail": "Delete Fail",
    "RemoveTerminalSuccessful": "Delete successfully",
    "Report.CardStatusDetail": "Card Status Report (Detail)",
    "Report.CardStatusSummary": "Card Status Report (Summary)",
    "Report": "Report",
    "ReportAccumulatedBranch": "Report Accumulated Branch",
    "ReportAccumulatedMerchant": "Report Accumulated Merchant",
    "ReportAccumulatedPos": "Report Accumulated Pos",
    "ReportAccumulatedTid": "Report Accumulated Tid",
    "ReportBankRefundTransaction": "Report Bank Refund Transaction",
    "ReportClosedBranchInPeriod": "Report Closed Branch In Period",
    "ReportClosedMerchantInPeriod": "Report Closed Merchant In Period",
    "ReportClosedPosInPeriod": "Report Closed Pos In Period",
    "ReportClosedTidInPeriod": "Report Closed Tid In Period",
    "ReportErrorStatisticThroughBank": "Report Error Statistic Through Bank",
    "ReportErrorStatisticThroughDebt": "Report Error Statistic Through Debt",
    "ReportGeneral": "Report general",
    "ReportGroup": "Report group",
    "ReportInitiatedRefundTransactionReport": "Report Initiated Refund Transaction Report",
    "ReportList": "Report list",
    "ReportMidsWithFeeAdjustment": "Report Mids With Fee Adjustment",
    "ReportMidsWithoutFeeDeclaration": "Report Mids Without Fee Declaration",
    "ReportNewlyOpenedBranchInPeriod": "Report Newly Opened Branch In Period",
    "ReportNewlyOpenedMerchantInPeriod": "Report Newly Opened Merchant In Period",
    "ReportNewlyOpenedPosInPeriod": "Report Newly Opened Pos In Period",
    "ReportNewlyOpenedTidInPeriod": "Report Newly Opened Tid In Period",
    "ReportOnline": "Report online",
    "ReportOperation": "Report operation",
    "ReportRefund": "Report refund",
    "ReportRefundTransaction": "Report Refund Transaction",
    "ReportRejectRefundTransaction": "Report Reject Refund Transaction",
    "ReportSuccessfulTransaction": "Report Successful Transaction",
    "ReportTransactionInformation": "Report Transaction Information",
    "Representative": "Representative",
    "Reprint": "Reprint",
    "Request": "Request",
    "RequestBankDeny": "Request Bank Deny Installment",
    "RequestCancel": "Request Cancel",
    "RequestDateTime": "Request Date Time",
    "RequestID": "Request ID",
    "RequestIDRoot": "RequestID Root",
    "RequestType": "Request Type",
    "Resend": "Resend",
    "ResendDebt": "Resend Debt",
    "ResendDebtConfirm": "Do you want to resend Debt",
    "Reset": "Reset",
    "ResetData": "Reset",
    "ResetPassword": "Reset password",
    "ResetPasswordTitle": "Reset password",
    "ResponseCode": "Response Code",
    "ResponseDateTime": "Response Date Time",
    "ResponseDescription": "Response Description",
    "Restore": "Restore",
    "Result": "Result",
    "Return": "Return",
    "ReturnHomePage": "Return to the home page",
    "ReturnToLoginPage": "Return to the login page",
    "Revenue": "Revenue",
    "Reversal": "Reversal",
    "ReversalDateTime": "Reversal Date Time",
    "ReversalDescription": "Reversal Description",
    "ReversalMemo": "Reversal Memo",
    "ReversalNote": "Reversal Note",
    "ReversalPurpose": "Reason",
    "ReversalReferenceNumber": "Reversal Reference Number",
    "ReversalSequenceNumber": "Reversal Sequence Number",
    "ReversalStatus": "Reversal Status",
    "ReversalUserID": "Reversal User",
    "Revert": "Revert",
    "ReversalDatetime": "Reversal Datetime",
    "RewardPoint": "Reward Point",
    "Role": "Role Name",
    "RoleID": "Role ID",
    "RootTransactionAmount": "Root Transaction Amount",
    "RowPerPage": "Rows per page",
    "SafeLimit": "Safe Limit",
    "SaleRequestID": "Sale Request ID",
    "SaleStatus": "Sale status",
    "Save": "Save",
    "SchemaName": "Schema Name",
    "Search": "Search",
    "SecretKey": "Secret Key",
    "Send": "Send",
    "SendDate": "Send Date",
    "SendEmail": "Send Email",
    "Sender": "Sender",
    "SendRequest": "Send request",
    "SendRequestApprove": "Send Request Approve",
    "SendSMS": "Send SMS",
    "SendToSPOS": "Send SPOS",
    "SequenceNumber": "Sequence Number",
    "SerialNumber": "Serial Number",
    "ServiceName": "Service Name",
    "Services": "Services",
    "SetPasswordTitle": "Set new password",
    "Setting": "Setting",
    "Settle": "Settle",
    "SettleConfirm": "Do you want to settle POS",
    "SettleFail": "Settle Fail",
    "SettlementBill": "Settlement Bill",
    "SettlementDate": "Settlement Date",
    "SettlementDateTime": "Settlement Date Time",
    "SettlementTime": "Settlement Time",
    "SettlementTimeList": "Settlement time list",
    "SettlementTransaction": "Transaction Settlement Search",
    "SettlementVoidSale": "Transaction Search",
    "ShortName": "Short Name",
    "SignatureImage": "Signature Image",
    "SignIn": "Sign In",
    "SignInTitle": "Sign In",
    "SignInUp": "Sign In",
    "SignOut": "Sign Out",
    "SoftPOSUser": "User App",
    "SoftwareInfo": "Software Information",
    "Sort": "Sort",
    "SourceAccount": "Source Account",
    "SourceAccountNumber": "Source Account",
    "SourceBankCode": "Source Bank Code",
    "SourceBranchCode": "Source Branch",
    "SourceData": "Data Source",
    "StartDateTime": "Start date time",
    "StaffID": "Staff ID",
    "State": "State",
    "StateCode": "State Code",
    "StateName": "State Name",
    "STATUS.APPROVED": "Approved",
    "STATUS.FOR_APPROVAL": "Pending",
    "STATUS.REJECTED": "Rejected",
    "Status": "Status",
    "StatusDate": "Status Date",
    "Step_From": "From",
    "Step_To": "To",
    "SubBranchData": "Sub Branch Data",
    "Subject": "Subject",
    "Submit": "Submit",
    "Success": "Success",
    "SuccessReconcile": "Completed",
    "Suggestion": "Suggestion",
    "SummaryType": "Summary Type",
    "Sync": "Sync",
    "System": "System",
    "SystemNote": "System Note",
    "T2PAmount": "App User Amount",
    "Tab.AccountLinkedInformation": "Linked Account",
    "Tab.PersonalInbox": "Personal Inbox",
    "Tab.UserAccountInformation": "User Account",
    "Tab.UserRequestInformation": "User Request",
    "TableCacheID": "Table Cache ID",
    "TableID": "Table ID",
    "TableName": "Table Name",
    "TablePermissionID": "Table Permission ID",
    "TabMCC": "MCC Info",
    "TaxNumber": "Tax Number/Business Certificate",
    "TC": "TC",
    "TemplateType": "Template type",
    "Tenor": "Tenor",
    "Tenor12": "12 months",
    "Tenor3": "3 months",
    "Tenor6": "6 months",
    "Tenor9": "9 months",
    "Tenor18": "18 months",
    "Tenor24": "24 months",
    "Tenor36": "36 months",
    "Terminal": "MID & TID",
    "TerminalCode": "TID",
    "TerminalInformation": "Terminal information",
    "TerminalManagement": "Terminal Management",
    "TID": "TID",
    "TIDAmount": "TID Amount",
    "Title": "Title",
    "TitleApproveRefund": "Result of approve refund ({$INTERPOLATION} transaction)",
    "TitleDeclineRefund": "Result of decline refund ({$INTERPOLATION} transaction)",
    "ToAddress": "To Address",
    "TokenCode": "Token Code",
    "Tooltip": "Tooltip",
    "TopUp": "Top Up",
    "TopupLimit": "Top Up Limit",
    "TopupTimes": "Top Up Times",
    "TotalAmount": "Total Amount",
    "TotalCaches": "Total Caches",
    "TotalTransaction": "Total Transaction :",
    "TotalTransactions": "Total Transactions",
    "TotalTxn_Settle": "Total Transactions Settle",
    "TotalTxnAmount_Settle": "Total Transactions Amount Settlement",
    "TotalUnSettlementAmount": "Total UnSettlement Amount",
    "Transaction": "Transaction",
    "TransactionAmount": "Transaction Amount",
    "TransactionDate": "Transaction Date",
    "TransactionDateTime": "Transaction Date Time",
    "TransactionDescription": "Transaction Description",
    "TransactionFee": "Transaction Fee",
    "TransactionID": "Transaction ID",
    "TransactionKeyword": "Transaction Keyword",
    "TransactionLatitude": "Transaction Latitude",
    "TransactionLimit": "Transaction Limit",
    "TransactionLongitude": "Transaction Longitude",
    "TransactionLimitMonth": "Transaction Limit Month",
    "TransactionMemo": "Transaction Memo",
    "TransactionNote": "Transaction Note",
    "TransactionPurpose": "Transaction Purpose",
    "TransactionResult": "Transaction Result",
    "TransactionRootID": "Transaction Root ID",
    "TransactionStatus": "Transaction Status",
    "TransactionTime": "Transaction Time",
    "TransactionTotalAmount": "Transaction Total Amount",
    "TransactionTypeCode": "Transaction Type Code",
    "TransactionUserID": "Transaction User ID",
    "TransactionBlockingInformation":"Transaction Blocking Information",
    "Transfer": "Transfer",
    "TransferTo": "Transfer To",
    "TransID": "Trans ID",
    "TypeOfAccounting": "Type of accounting",
    "UnEnroll": "UnEnroll",
    "Unlock": "Unlock",
    "UnSettlementAmount": "UnSettlement Amount",
    "UnSettlementTime": "UnSettlement Time",
    "Unsuccessful": "Unsuccessful",
    "UPDATE_FEE": "Update Fee",
    "Update": "Update",
    "UpdateDetails": "Update details",
    "UpdateAccountConfirm": "Confirm update user information?",
    "UpdateFee": "Update Fee",
    "UpdateProcessResult": "Update process result",
    "UpdateProcessResultReconciliation": "Update process result reconciliation",
    "UpdateReconciliationFile": "Update Reconciliation File",
    "UpdateRefundStatus": "Update Status",
    "UpdateRequest": "Update Request",
    "UpdateReconciliationStatus": "Update Reconciliation Status",
    "Upload": "Upload",
    "UploadDate": "Upload Date",
    "User": "User",
    "UserField01_pos": "UserField01",
    "UserField01": "User Field 01",
    "UserField02": "User Field 02",
    "UserField03": "User Field 03",
    "UserField04": "User Field 04",
    "UserField05": "User Field 05",
    "UserID": "User ID",
    "UserIDApprove": "User Approved/Declined/Cancel",
    "UserIDCreate": "User Create",
    "UserIDGenerate": "User ID Generate",
    "UserIDModify": "User Modify",
    "UserIDProcess": "User Process",
    "UserIDSend": "User ID Send",
    "UserInformation": "User Information",
    "UserIsDisabled": "This account is temporarily disabled. Please contact the administration for assistance.",
    "UserIsLocked": "This account is temporarily locked. Please contact the administration for assistance.",
    "UserManagement": "User Management",
    "UserName": "User Name",
    "UsernameInUsed": "Username have been used",
    "UsernameUpdate": "Username Update",
    "UserT2P": "USER SOFTPOS",
    "UserTerminalID": "User Terminal ID",
    "UserUpload": "User Upload",
    "UseT2P": "Use for T2P",
    "Validate": "Validate",
    "Validation.Existed": "{$INTERPOLATION} already exists",
    "Validation.RequireField": "Please fill {$INTERPOLATION}",
    "ValidTransaction": "Valid Transaction :",
    "ValueOfTxn": "Value Of Txn",
    "ValueOfTxnSettlement": "Value of Settlement transactions",
    "ValueOfTxnSuccess": "Value of successful transactions",
    "ValueOfTxnUnsuccess": "Value of Unsuccessful transactions",
    "Verify": "Verify",
    "View": "View",
    "ViewDetail": "View Detail",
    "ViewHistory": "View History",
    "VisibleAdminOnly": "Visible by Administrators only",
    "VNBank": "VN Bank",
    "VNBankFix": "VN Bank Fix",
    "VNJCB": "VN JCB",
    "VNJCBFix": "VN JCB Fix",
    "VNMaster": "VN Master",
    "VNMasterFix": "VN Master Fix",
    "VNPAY_Pay": "VNPAY Pay",
    "VNPAYSSO": "VNPAY SSO",
    "VNPAYQR": "VNPAY QR",
    "VNPAYWallet": "VNPAY Wallet",
    "VNUnionPay": "VN Union Pay",
    "VNUnionPayFix": "VN Union Pay Fix",
    "VNVisa": "VN Visa",
    "VNVisaFix": "VN Visa Fix",
    "VoidDateTime": "Void DateTime",
    "VoidRequest": "Void Request",
    "VoidStatus": "Void Status",
    "WaitingForAction": "Waiting for Action",
    "WalletAcc": "VNPAY ID",
    "Ward": "Ward",
    "Wards": "Ward",
    "Website": "Website",
    "WillEffective": "Coming",
    "InvalidEmailVNP": "Invalid email. Only use @vnpay.vn",
    "RoleCode": "Role code",
    "Yes": "Yes",
    "ZZ": "End of translation file. Don't add any lines after this line. Sort dictionary ascending by key before commit"
  }
}
